$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.checkbox {
  display: none;
}

.option_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  height: 2.8125rem; //45
  margin-bottom: 0.6875rem; //11
  cursor: pointer;
  &.checked {
    background: #f4f4f4;
    border-radius: 10px;
  }

  .check_mark_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 3.3125rem; //53
    height: 2.8125rem; //45

    img {
      width: 1.375rem; //22
      height: auto;
    }
  }

  .option_title {
    flex: 1;
  }
}
