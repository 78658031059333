$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: stretch;
    height: 100%;
  
    .description {
      text-align: left;
    }
  
    .animation-container {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 100%;
      flex: 1;
      overflow: hidden;
      background-color: var(--page-background-color);
    }
  }
  