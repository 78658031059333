$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.formInputRow {
  position: relative;

  & > span {
    &:first-child {
      position: absolute;
      left: 0;
      top: 0;
      width: 35%;
      font-weight: bold;
      font-size: 1rem; //16
      line-height: 1.2;
      z-index: 1;
      height: 3.75rem; //60
      display: flex;
      align-items: center;
    }
  }
}

.control {
  background-color: white;
  border-bottom: 1px solid rgba(25, 25, 25, 0.32);
  border-radius: 0;
  padding-left: 35%;
}
