$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.progress {
  height: 1.25rem; //20
  background: #f5f5f5;
  position: relative;
  margin-top: 1rem; //16
  border-radius: 0.3125rem; //5

  .bar {
    border-radius: 0.3125rem; //5
    background-color: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    transition: width 0.6s ease;
  }
}
