$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.Select {
  position: relative;
  .hasError {
    text-align: left;
    font-size: 0.8rem;
    margin-top: 3px;
    color: $c-danger;
  }
}

.leftPadding30Perc {
  padding: 0.5rem 0.7rem 0.5rem 40% !important;
}

:export {
  primary: var(--primary);
  secondary: var(--secondary);
  primary25: '#fff';
  primary50: lighten($c-secandary, 45%);
  gray: $c-grey;
  bodyGray: #fff;
}
