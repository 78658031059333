$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;
button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.heading {
  font-family: var(--heading-font-family);
  color: var(--heading-text-color);
  margin-bottom: 1.5rem; //24
  text-align: left;
  font-weight: 600;
  font-size: var(--heading-font-size);
  line-height: 1.1;
  letter-spacing: -0.03125rem; //-0.5px
}
