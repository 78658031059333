$c-primary: #68f3be;
$c-secandary: #68f3be;
$dots: false;
$body-color-overrided: #1e005a;
$btn-text-overrided: #1e005a;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'Montserrat', sans-serif;
$font-family-heading: 'Poppins', sans-serif;

      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Poppins';
        src: url('/fonts/Poppins-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-BoldItalic.ttf');
        font-weight: 600;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-MediumItalic.ttf');
        font-weight: 500;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Italic.ttf');
        font-weight: 400;
        font-style: italic;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-Light.ttf');
        font-weight: 300;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'Montserrat';
        src: url('/fonts/Montserrat-LightItalic.ttf');
        font-weight: 300;
        font-style: italic;
      }

    button.btn-override {
font-weight : bold;font-family : $font-family-heading;&.btn-rounded-override {
border-radius : 1.5rem;
}&.btn-transparent-override {
color : $btn-text-overrided;
}
}

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.mobile {
  @media only screen and (max-width: 320px) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 321px) {
    font-size: 16px !important;
  }

  @media only screen and (min-width: 480px) {
    font-size: 20px !important;
  }

  @media only screen and (min-width: 720px) {
    font-size: 24px !important;
  }

  @media only screen and (min-width: 960px) {
    font-size: 28px !important;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 32px !important;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 36px !important;
  }
}

.desktop {
  font-size: 18px !important;
}
